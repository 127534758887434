import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FileFormatEnum } from 'types/generated/client';
import AddPhotoV2 from 'svg/AddPhotoV2';
import CrossPhoto from 'svg/CrossPhoto';
import classNames from 'styles/utils/classNames';
import UploadProgressBar from './UploadProgressBar';

const MEGABYTE = 1024 * 1024;
const DEFAULT_MAX_FILE_SIZE = 10000 * MEGABYTE;

export interface SelectedFile extends Partial<File> {
  url: string;
  fileFormat: FileFormatEnum;
}

interface Props extends DropzoneOptions {
  onSelectFiles(files: File[]): void;
  onRemoveFile?(file: SelectedFile): void;
  beforeFileSelection?(): boolean;
  styles?: string;
  selectedFiles: SelectedFile[];
  uploadProgress: number | null;
  helperText?: string;
}

export default function FileUploader({
  onSelectFiles,
  onRemoveFile,
  beforeFileSelection,
  styles = '',
  uploadProgress,
  selectedFiles,
  helperText,
  ...rest
}: Props) {
  const uploading = uploadProgress !== null && uploadProgress < 100;

  const onDrop = async (acceptedFiles: File[]) => {
    if (beforeFileSelection) {
      const shouldOpen = beforeFileSelection();
      if (!shouldOpen) return;
    }
    onSelectFiles(acceptedFiles);
  };

  const handleRemoveFile = (file: SelectedFile) => {
    onRemoveFile?.(file);
  };

  const handleBeforePickerOpens = (event: React.MouseEvent) => {
    if (beforeFileSelection) {
      const shouldOpen = beforeFileSelection();
      if (!shouldOpen) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    disabled: uploading,
    ...rest,
    maxSize: rest.maxSize || DEFAULT_MAX_FILE_SIZE,
  });

  return (
    <div className="flex w-full flex-col">
      <div
        {...getRootProps({
          onClick: handleBeforePickerOpens,
        })}
        className={classNames(
          `flex w-full items-center justify-center bg-color-bg-lightmode-tertiary pb-8 pt-8 dark:bg-color-bg-darkmode-tertiary`,
          styles,
        )}
      >
        <input {...getInputProps()} />
        <div
          className={classNames(
            'flex flex-col items-center px-2 text-center text-color-text-lightmode-placeholder dark:text-color-text-darkmode-placeholder',
            !uploading ? 'cursor-pointer' : 'cursor-wait',
          )}
        >
          {uploading ? (
            <UploadProgressBar progress={uploadProgress} />
          ) : (
            <AddPhotoV2 className="h-8 w-8 [&>path]:fill-color-text-lightmode-icon dark:[&>path]:fill-color-text-darkmode-icon" />
          )}
          <span className="mt-2 px-4">
            <mark className="typography-product-body-highlight bg-transparent text-color-text-brand dark:text-color-text-brand">
              Click to upload{' '}
            </mark>
            or drag and drop
          </span>
          {helperText && <span>{helperText}</span>}
        </div>
      </div>

      <div className="mt-2 flex flex-wrap gap-4">
        {selectedFiles?.length > 0 &&
          selectedFiles?.map((selectedFile, index) => (
            <div className="relative" key={index}>
              {selectedFile.fileFormat === FileFormatEnum.Video ? (
                <video
                  src={selectedFile.url}
                  className="h-[7.8125rem] w-[7.8125rem] rounded-md border border-color-border-input-lightmode object-cover dark:border-color-border-input-darkmode"
                  controls
                />
              ) : (
                <img
                  src={selectedFile.url}
                  alt="Selected"
                  className="h-[7.8125rem] w-[7.8125rem] rounded-md border border-color-border-input-lightmode object-cover object-top dark:border-color-border-input-darkmode"
                />
              )}
              <span
                className="absolute right-1 top-1 inline-flex cursor-pointer items-center justify-center rounded-full bg-color-bg-lightmode-primary p-1 hover:opacity-95 dark:bg-color-bg-darkmode-primary"
                onClick={() => handleRemoveFile(selectedFile)}
              >
                <CrossPhoto className="h-4 w-4" />
              </span>
            </div>
          ))}
      </div>
    </div>
  );
}
