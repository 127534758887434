import React, { useCallback, useMemo, useState } from 'react';
import { Controller, FieldValues, Path } from 'react-hook-form';
import { VideoAnalysisSkillsTypeEnum } from 'utils/shared/coachBuilder';
import { useModal } from 'hooks/useModal';
import PlusPlain from 'svg/PlusPlain';
import Pill from 'components/Pill';
import AddSkillModal from './AddSkillModal';
import { SkillsProps } from './types';
import { getLessonSkills, toggleSkillSelection } from './utils';

const Skills = <T extends FieldValues>({ control, skillType, errors }: SkillsProps<T>) => {
  const {
    isOpen: isAddSkillModalOpen,
    openModal: openAddSkillModal,
    closeModal: closeAddSkillModal,
  } = useModal();

  const [customSkills, setCustomSkills] = useState<Array<string>>([]);

  const lessonSkills = useMemo(() => getLessonSkills(customSkills), [customSkills]);

  const handleAddSkill = useCallback((newSkill: string) => {
    setCustomSkills((curr) => [...curr, newSkill]);
  }, []);

  const getRadio = (label: string, value: VideoAnalysisSkillsTypeEnum) => (
    <Controller
      name={'skillType' as Path<T>}
      control={control}
      render={({ field }) => (
        <label className="flex cursor-pointer items-center gap-2">
          <div className="relative h-5 w-5 shrink-0 rounded-full">
            <input
              type="radio"
              id={`skill-${value}`}
              className="peer hidden"
              {...field}
              checked={field.value === value}
              onChange={() => field.onChange(value)}
            />
            <div className="absolute inset-[7px] z-10 rounded-full peer-checked:bg-white" />
            <div className="absolute inset-0 z-0 rounded-full border peer-checked:bg-black" />
          </div>
          <span className="typography-product-button-label-medium text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            {label}
          </span>
        </label>
      )}
    />
  );

  return (
    <>
      <div className="mt-4">
        <p className="typography-product-subheading text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          Which skill are you focused on?
        </p>
        <div className="mt-4 flex flex-col gap-3">
          {getRadio('General Skills', VideoAnalysisSkillsTypeEnum.GENERAL_FEEDBACK)}
          {getRadio('Specific Skills', VideoAnalysisSkillsTypeEnum.SPECIFIC_SKILLS)}
        </div>

        {skillType === VideoAnalysisSkillsTypeEnum.SPECIFIC_SKILLS && (
          <Controller
            name={'skills' as Path<T>}
            control={control}
            render={({ field }) => (
              <div className="mt-4">
                <div className="flex flex-wrap items-start gap-2">
                  {lessonSkills.map((lessonSkill) => (
                    <Pill
                      key={lessonSkill.value}
                      text={lessonSkill.label}
                      selected={field.value?.includes(lessonSkill.value)}
                      onClick={() =>
                        field.onChange(toggleSkillSelection(field.value || [], lessonSkill.value))
                      }
                      mode="select"
                    />
                  ))}

                  <Pill
                    text="Custom"
                    onClick={() => openAddSkillModal()}
                    mode="select"
                    icon={<PlusPlain className="h-4 w-4 scale-125" />}
                  />
                </div>
                {errors.skills?.message && typeof errors.skills.message === 'string' && (
                  <p className="mt-2 text-xs text-color-error">{errors.skills.message}</p>
                )}
              </div>
            )}
          />
        )}
      </div>
      <AddSkillModal
        isOpen={isAddSkillModalOpen}
        handleAddSkill={handleAddSkill}
        handleClose={() => closeAddSkillModal()}
      />
    </>
  );
};

export default Skills;
